import { Link, useRouteError } from "react-router-dom";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import "./error-page.css";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Helmet>
        <title>Oops, there was an error! | Wolfram Demonstrations Project</title>
      </Helmet>
      <Logo fill="hsl(0, 0%, 95%)" />
      <div id="page-contents">
        <h1>Oops, there was an error!</h1>
        <p>{error.statusText || error.message}</p>
        <p><Link to="">Back to the Wolfram Demonstrations Project</Link></p>
      </div>
    </div>
  );
}

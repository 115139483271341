import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData, useParams } from "react-router-dom";

import TopicTree from "../components/topic-tree/topic-tree";

export default function TopicLeafPage() {
  let { topicID } = useParams();
  const loadedPageData = useLoaderData();
  const pageData = loadedPageData.filter((obj) => obj.ID === topicID);
  let title = "";
  if (pageData.length > 0) {
    title = pageData[0].Title + " Topics";
  }

  useEffect(() => {
    if (pageData.length === 0) {
      throw new Error("Page not found");
    }
  }, [pageData]);

  return (
    <>
      <Helmet>
        <title>{title} | Wolfram Demonstrations Project</title>
      </Helmet>
      <TopicTree title={title} data={pageData} startOpen={true} />
    </>
  );
}

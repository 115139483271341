import * as WolframNotebookEmbedder from "wolfram-notebook-embedder";
import { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import "./guidelines-page.css";

export default function GuidelinesPage(props) {
  const notebookRef = useRef(null);

  useEffect(() => {
    WolframNotebookEmbedder.embed(
      props.guidelinesNotebook,
      notebookRef.current,
      { showRenderProgress: false }
    );
  }, [props.guidelinesNotebook]);

  return (
    <main className="main guidelines-page">
      <Helmet>
        <title>Authoring Guidelines | Wolfram Demonstrations Project</title>
      </Helmet>
      <section>
        <div className="inner">
          <h1 id="demoTitle" className="visually-hidden">
            Authoring Guidelines
          </h1>
          <div id="notebook-embed" ref={notebookRef} />
        </div>
      </section>
    </main>
  );
}

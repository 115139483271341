import { Link } from "react-router-dom";

import "./resource-table-page.css";

function ResourceTile(props) {
  return (
    <div className="resource">
      <Link to={"../" + props.id + "/"}>
        <p>
          <img
            src={props.thumbnail.URL}
            alt=""
            width={props.thumbnail.Width}
            height={props.thumbnail.Height}
          />
          <br />
          {props.name}
          <br />
          <span className="updated-text">{props.updatedText}</span>
        </p>
      </Link>
    </div>
  );
}

export default function ResourceTablePage(props) {
  const tiles = props.resources.map((resource) => (
    <ResourceTile
      key={resource.ID}
      id={resource.ID}
      name={resource.Title}
      thumbnail={resource.Thumbnail}
    />
  ));
  return (
    <main className="main resource-table-page">
      <section className="section">
        <div className="inner">
          <h1>{props.title}</h1>
          <div className="under-bar">
            <p className="demonstrations">Demonstrations</p>
            <p className="count">
              {props.resources.length} resource
              {props.resources.length > 1 ? "s" : ""}
            </p>
          </div>
          <div className="resource-grid">{tiles}</div>
        </div>
      </section>
    </main>
  );
}

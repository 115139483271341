import Logo from "../../components/Logo";
import "./loading-page.css";

export default function LoadingPage() {
  return (
    <div id="loading-page">
      <Logo fill="hsl(0, 0%, 95%)" />
    </div>
  );
}

import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";
import ResourceTablePage from "../components/resource-table-page/resource-table-page";

export default function AuthorListPage() {
  let pageName = useLoaderData().PageName;
  let resources = useLoaderData().Resources;

  return (
    <>
      <Helmet>
        <title>
          {pageName} Demonstrations | Wolfram Demonstrations Project
        </title>
      </Helmet>
      <ResourceTablePage title={pageName} resources={resources} />
    </>
  );
}

import { useRef, useEffect, useState, Fragment } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Popover from "@radix-ui/react-popover";
import * as Tabs from "@radix-ui/react-tabs";
import * as WolframNotebookEmbedder from "wolfram-notebook-embedder";
import SidebarTabs from "./sidebar-tabs";
import LoadingSpinner from "../../components/LoadingSpinner";

import "./demonstration-page.css";

function DownloadButton(props) {
  return (
    <a
      className="buttonUnderExample"
      href={props.openExampleNotebookInCloudURL}
    >
      {props.label}
    </a>
  );
}
function ManipulateC2C(props) {
  const initialLabel = props.label;
  const [buttonLabel, setButtonLabel] = useState(initialLabel);
  function handleClick() {
    navigator.clipboard.writeText(props.clickToCopyText).then(() => {
      setButtonLabel("Copied!");
      setTimeout(() => {
        setButtonLabel(initialLabel);
      }, 5000);
    });
  }
  return (
    <button className="buttonUnderExample" onClick={handleClick}>
      {buttonLabel}
    </button>
  );
}
function SourceButtonC2C(props) {
  const initialLabel = props.label;
  const [buttonLabel, setButtonLabel] = useState(initialLabel);
  function handleClick() {
    navigator.clipboard.writeText(props.clickToCopyText).then(() => {
      setButtonLabel("Copied!");
      setTimeout(() => {
        setButtonLabel(initialLabel);
      }, 5000);
    });
  }
  return <button onClick={handleClick}>{buttonLabel}</button>;
}
function SourceButton(props) {
  return (
    <Popover.Root>
      <Popover.Trigger className="buttonUnderExample Source">
        Source Code
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="PopoverContent Source">
          {props.sourceNotebookButton && (
            <p>
              <Popover.Root>
                <Popover.Trigger>
                  {props.sourceNotebookButton.Label}
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content className="PopoverContent Download">
                    <p>
                      In order to view the notebook, you must have a Wolfram
                      Notebook System installed on your device, such as the free{" "}
                      <a href="https://www.wolfram.com/player/">
                        Wolfram Player
                      </a>
                      , or other{" "}
                      <a href="https://www.wolfram.com/products/">
                        Wolfram Language products
                      </a>
                      .
                    </p>
                    <p>
                      <a
                        className="button red"
                        href={props.sourceNotebookButton.URL}
                      >
                        {props.sourceNotebookButton.Label}
                      </a>
                    </p>
                    <Popover.Arrow className="PopoverArrow" />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </p>
          )}
          {props.resourceObjectButton && (
            <p>
              <SourceButtonC2C
                label={props.resourceObjectButton.Label}
                clickToCopyText={props.resourceObjectButton.Content}
              />
            </p>
          )}
          <Popover.Arrow className="PopoverArrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
function LinkToAuthors(props) {
  return props.authors.map((author, index) => (
    <span key={author.Name}>
      {index ? ", " : ""}
      {author.Label} <Link to={"../authors/" + author.Slug}>{author.Name}</Link>
    </span>
  ));
}
function ContributedBySection(props) {
  return (
    <div id="contributed-section">
      <p className="contributed-by">
        {props.comment && (
          <Fragment>
            <span>{props.comment}</span>
            <br />
          </Fragment>
        )}
        {props.label} <LinkToAuthors authors={props.authors} />
        {props.date && (
          <>
            &nbsp;<span id="publishdate">({props.date})</span>
          </>
        )}
        <span
          className="contentTerms"
          id="publishdate"
          style={{ lineHeight: "24px" }}
        >
          <br />
          Open content licensed under{" "}
          <a href="http://www.wolfram.com/legal/terms/wolfram-demonstrations-project.html">
            CC BY-NC-SA
          </a>
        </span>
      </p>
    </div>
  );
}
function ExternalLinksSection(props) {
  const links = props.links.map((link) => (
    <li key={link.URL}>
      <a href={link.URL}>{link.Text}</a>
    </li>
  ));
  return (
    <>
      <h2>{props.headerText}</h2>
      <ul>{links}</ul>
      <hr />
    </>
  );
}
function SnapshotsSection(props) {
  const triggers = props.snapshots.map((snapshot, index) => {
    return (
      <Tabs.Trigger
        key={snapshot}
        className="SnapshotsTabsTrigger"
        value={"tab" + index}
      >
        <img src={snapshot} alt="" />
      </Tabs.Trigger>
    );
  });
  const content = props.snapshots.map((snapshot, index) => {
    return (
      <Tabs.Content
        key={snapshot}
        className="SnapshotsTabsContent"
        value={"tab" + index}
      >
        <img src={snapshot} alt="" />
      </Tabs.Content>
    );
  });
  return (
    <>
      <h2>Snapshots</h2>
      <Tabs.Root className="SnapshotsTabsRoot">
        <Tabs.List className="SnapshotsTabsList" aria-label="Choose a snapshot">
          {triggers}
        </Tabs.List>
        {content}
      </Tabs.Root>
      <hr />
    </>
  );
}
function MoreTopicsSection(props) {
  const topicLinks = props.topics.map((topic) => (
    <li key={topic.ID}>
      <Link to={"../topic/" + topic.ID} reloadDocument={true}>
        {topic.Name}
      </Link>
    </li>
  ));
  return (
    <div className="related-topics">
      <p className="sidebar-heading">Related Topics</p>
      <ul>
        {topicLinks}
        <li>
          <Link to="../topics/">Browse all topics</Link>
        </li>
      </ul>
    </div>
  );
}

function formatDate(date) {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

function DemonstrationDownloadOverlay(props) {
  return (
    <div className="ddoverlay-frame">
      <div className="ddoverlay-tile">
        <p className="title">This demonstration is optimized for desktop</p>
        <p className="link-frame">
          <a href={props.downloadURL}>
            <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16 4h11.16A2.84 2.84 0 0 1 30 6.84v16.32A2.84 2.84 0 0 1 27.16 26H20v2h3.5v2h-17v-2H10v-2H2.84A2.84 2.84 0 0 1 0 23.16V6.84A2.84 2.84 0 0 1 2.84 4H14V.5h2V4Zm-2 2H2.84a.84.84 0 0 0-.84.84v16.32c0 .46.38.84.84.84h24.32c.46 0 .84-.38.84-.84V6.84a.84.84 0 0 0-.84-.84H16v10.59l2.3-2.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.42l2.3 2.3V6Z"
                fill-opacity=".5"
              />
            </svg>
            <span>Download to Desktop</span>
          </a>
        </p>
      </div>
    </div>
  );
}

function FullScreenModal(props) {
  const manipulateRef = useRef(null);
  const [manipulateLoading, setManipulateLoading] = useState(true);

  useEffect(() => {
    function moveAndResizeManipulate() {
      function doMoveAndResize() {
        manipulateRef.current.style.transform = "unset";

        const manipulateSize = manipulateRef.current
          .querySelector(".TagBoxContainer")
          .getBoundingClientRect();
        const manipulateSizeFudge = 2;
        manipulateRef.current.style.position = "absolute";
        manipulateRef.current.style.height =
          manipulateSize.height + manipulateSizeFudge + "px";
        manipulateRef.current.style.width =
          manipulateSize.width + manipulateSizeFudge + "px";
        manipulateRef.current.style.top =
          window.innerHeight / 2 -
          (manipulateSize.height + manipulateSizeFudge) / 2 +
          "px";
        manipulateRef.current.style.left =
          window.innerWidth / 2 -
          (manipulateSize.width + manipulateSizeFudge) / 2 +
          "px";
        manipulateRef.current.style.transform =
          "scale(" +
          (Math.floor(
            Math.min(
              window.innerHeight / manipulateSize.height,
              window.innerWidth / manipulateSize.width
            ) * 10
          ) /
            10 -
            0.2) +
          ")";
      }
      window.setTimeout(doMoveAndResize, 20);
      window.setTimeout(doMoveAndResize, 400);
    }
    window.manRef = manipulateRef.current;

    WolframNotebookEmbedder.embed(
      props.manipulateInfo.URL,
      manipulateRef.current,
      { showRenderProgress: false }
    ).then((embedded) => {
      embedded.addEventListener("initial-render-done", () => {
        manipulateRef.current.style.opacity = 1;
        setManipulateLoading(false);

        moveAndResizeManipulate();
      });
      window.addEventListener("click", moveAndResizeManipulate);
      window.addEventListener("resize", moveAndResizeManipulate);
      return () => {
        window.removeEventListener("click", moveAndResizeManipulate);
        window.removeEventListener("resize", moveAndResizeManipulate);
      };
    });
  }, [props.manipulateInfo]);
  return (
    <div id="fullScreenModal">
      <button
        onClick={() => {
          props.closeAction();
        }}
        className="close-button"
      >
        <svg viewBox="0 0 16 16">
          <title>Close</title>
          <path d="M2,2L14,14" />
          <path d="M14,2L2,14" />
        </svg>
      </button>
      {manipulateLoading && <LoadingSpinner />}
      <div className="cloudembed section">
        <div ref={manipulateRef} style={{ opacity: 0 }} />
      </div>
    </div>
  );
}

export default function DemonstrationPage() {
  const pageData = useLoaderData();

  const [showFullScreenModal, setFullScreenModal] = useState(false);
  function openFullScreenModal(e) {
    setFullScreenModal(true);
  }
  function closeFullScreenModal(e) {
    setFullScreenModal(false);
  }

  const manipulateRef = useRef(null);
  const captionRef = useRef(null);
  const detailsRef = useRef(null);
  const referencesRef = useRef(null);
  useEffect(() => {
    const cacheurl = pageData.Manipulate.URL.replace(
      /\/(obj|objects)\//,
      "/statichtml/"
    );
    fetch(cacheurl, { method: "GET" })
      .then((response) => response.text())
      .then((text) => {
        manipulateRef.current.innerHTML = text;
      });

    const loadingLiveNb = document.querySelector("#loading-manipulate");
    console.time("(event) initial-render-done");
    console.time("initial-render-done");
    loadingLiveNb.setAttribute("data-show", "true");

    WolframNotebookEmbedder.embed(
      pageData.Manipulate.URL,
      manipulateRef.current,
      { showRenderProgress: false }
    ).then((embedded) => {
      embedded.addEventListener("first-paint-done", ({ showingStaticHTML }) => {
        console.log(
          `First paint done ${
            showingStaticHTML ? "showing static HTML" : "live-rendering"
          }`
        );
      });
      embedded.addEventListener("initial-render-done", () => {
        console.timeEnd("(event) initial-render-done");
        loadingLiveNb.setAttribute("data-show", "false");
      });
    });

    pageData.Caption &&
      WolframNotebookEmbedder.embed(
        pageData.Caption.URL,
        captionRef.current,
        typeof pageData.Caption.Width === "undefined" &&
          pageData.Caption.Height === "undefined"
          ? { showRenderProgress: false, allowInteract: false }
          : {
              width: pageData.Caption.Width,
              maxHeight: pageData.Caption.Height,
              showRenderProgress: false,
              allowInteract: false,
            }
      );
    pageData.Details &&
      WolframNotebookEmbedder.embed(
        pageData.Details.URL,
        detailsRef.current,
        typeof pageData.Details.Width === "undefined" &&
          pageData.Details.Height === "undefined"
          ? { showRenderProgress: false, allowInteract: false }
          : {
              width: pageData.Details.Width,
              maxHeight: pageData.Details.Height,
              showRenderProgress: false,
              allowInteract: false,
            }
      );
    pageData.References &&
      WolframNotebookEmbedder.embed(
        pageData.References.URL,
        referencesRef.current,
        typeof pageData.References.Width === "undefined" &&
          pageData.References.Height === "undefined"
          ? { showRenderProgress: false, allowInteract: false }
          : {
              width: pageData.References.Width,
              maxHeight: pageData.References.Height,
              showRenderProgress: false,
              allowInteract: false,
            }
      );
  }, [pageData]);

  return (
    <main
      className={
        showFullScreenModal
          ? "main demonstration modalOpen"
          : "main demonstration"
      }
    >
      <Helmet>
        <title>{pageData.Title} | Wolfram Demonstrations Project</title>
      </Helmet>
      {showFullScreenModal && (
        <FullScreenModal
          manipulateInfo={pageData.Manipulate}
          closeAction={closeFullScreenModal}
        />
      )}
      <section>
        <div className="inner">
          <h1 id="demoTitle">{pageData.Title}</h1>
          <div className="left">
            <div className="object">
              <div className="cloudembed">
                <div ref={manipulateRef} />
                {!pageData.CloudCompatible && (
                  <DemonstrationDownloadOverlay
                    downloadURL={pageData.SourceNotebookButton.URL}
                  />
                )}
              </div>
              <div id="loading-manipulate" data-show="false">
                Initializing live version
              </div>
              <div className="button-frame">
                {pageData.ExampleNotebookButton && (
                  <DownloadButton
                    label={pageData.ExampleNotebookButton.Label}
                    openExampleNotebookInCloudURL={
                      pageData.ExampleNotebookButton.URL
                    }
                  />
                )}
                {pageData.CopyManipulateButton && (
                  <ManipulateC2C
                    label={pageData.CopyManipulateButton.Label}
                    clickToCopyText={pageData.CopyManipulateButton.Content}
                  />
                )}
                {(pageData.SourceNotebookButton ||
                  pageData.ResourceObjectButton) && (
                  <SourceButton
                    sourceNotebookButton={pageData.SourceNotebookButton}
                    resourceObjectButton={pageData.ResourceObjectButton}
                  />
                )}
                {pageData.CloudCompatible && (
                  <button
                    onClick={() => {
                      openFullScreenModal();
                    }}
                    className="buttonUnderExample FullScreen"
                  >
                    <svg viewBox="0 0 16 16" width="1em" height="1em">
                      <title>Fullscreen Demonstration</title>
                      <path
                        d="M3.41 14H6v2H0v-6h2v2.59l3.3-3.3 1.4 1.42L3.42 14Zm9.18 0-3.3-3.3 1.42-1.4L14 12.58V10h2v6h-6v-2h2.59ZM14 3.41l-3.3 3.3-1.4-1.42L12.58 2H10V0h6v6h-2V3.41Zm-12 0V6H0V0h6v2H3.41l3.3 3.3-1.42 1.4L2 3.42Z"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
            {pageData.Caption && (
              <div ref={captionRef} style={{ margin: "2rem 0" }} />
            )}
            {pageData.AuthorInformation && (
              <ContributedBySection
                label={pageData.AuthorInformation.Label}
                authors={pageData.AuthorInformation.Authors}
                comment={pageData.AuthorInformation.Comment}
                date={formatDate(pageData.PublishedDate)}
              />
            )}
            <hr />
            {pageData.Snapshots && (
              <SnapshotsSection snapshots={pageData.Snapshots} />
            )}
            {pageData.Details && (
              <>
                <h2>Details</h2>
                <div ref={detailsRef} />
                <hr />
              </>
            )}
            {pageData.References && (
              <>
                <h2>References</h2>
                <div ref={referencesRef} />
                <hr />
              </>
            )}
            {pageData.ExternalLinks && (
              <ExternalLinksSection
                headerText={pageData.ExternalLinks.Label}
                links={pageData.ExternalLinks.Links}
              />
            )}
            {pageData.Citation && (
              <>
                <h2>Permanent Citation</h2>
                <p>
                  {pageData.Citation.Authors && (
                    <>
                      <LinkToAuthors authors={pageData.Citation.Authors} />
                      <br />
                    </>
                  )}
                  <Link to=".">"{pageData.Title}"</Link>
                  <br />
                  <Link to=".">
                    https://demonstrations.wolfram.com/{pageData.ID}/
                  </Link>
                  <br />
                  <Link to="">Wolfram Demonstrations Project</Link>
                  <br />
                  {pageData.Citation.Date && (
                    <>
                      {pageData.Citation.DateLabel}{" "}
                      {formatDate(pageData.Citation.Date)}
                    </>
                  )}
                </p>
              </>
            )}
          </div>

          <div className="sidebar">
            <SidebarTabs
              relatedLabel={pageData.RelatedDemonstrations.Label}
              relatedURL={pageData.RelatedDemonstrations.URL}
              authorLabel={pageData.MoreByAuthor.Label}
              authorURL={pageData.MoreByAuthor.URL}
            />
            {pageData.Categories.Categories && (
              <MoreTopicsSection topics={pageData.Categories.Categories} />
            )}
          </div>

          {/* <pre style={{ font: ".5em monospace", marginTop: 100 }}>
            {JSON.stringify(pageData, null, 2)}
          </pre> */}
        </div>
      </section>
    </main>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import Root from "./routes/root/root";
import LoadingPage from "./routes/loading-page/loading-page";
import ErrorPage from "./routes/error-page/error-page";
import HomePage from "./routes/home-page/home-page";
import LatestPage from "./routes/latest-page";
import TopicsTreePage from "./routes/topic-tree-index-page";
import TopicsLeafPage from "./routes/topic-tree-leaf-page";
import SearchPage from "./routes/search-page";
import TopicListPage from "./routes/topic-list-page";
import AuthorListPage from "./routes/author-list-page";
import UploadPage from "./routes/upload-page/upload-page";
import GuidelinesPage from "./routes/guidelines-page/guidelines-page";
import DemonstrationPage from "./routes/demonstration-page/demonstration-page";

const router = createBrowserRouter([
  {
    path: process.env.REACT_APP_DEMONSTRATION_URL_DISPATCHER,
    basename: process.env.REACT_APP_DEMONSTRATION_URL_DISPATCHER,
    element: (
      <Root
        createNotebookDownload={process.env.REACT_APP_CREATE_NOTEBOOK_DOWNLOAD}
        randomPageAPI={process.env.REACT_APP_RANDOM_PAGE_API}
      />
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        loader: async () => {
          return fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}home-page.json`
          );
        },
        element: <HomePage />,
      },
      {
        path: "index.html",
        element: <Navigate to="../" />,
      },
      {
        path: "about.php",
        element: <Navigate to="../" />,
      },
      {
        path: "participate.php",
        element: <Navigate to="../" />,
      },
      {
        path: "participate/*",
        element: <Navigate to="../" />,
      },
      {
        path: "feeds/*",
        element: <Navigate to="../" />,
      },
      {
        path: "topics/",
        loader: async () => {
          return fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}topics-tree.json`
          );
        },
        element: <TopicsTreePage />,
      },
      {
        path: "topics.php",
        element: <Navigate to="../topics" />,
      },
      {
        path: "topic.html",
        element: <Navigate to="../topics" />,
      },
      {
        path: "education.html",
        element: <Navigate to="../topics" />,
      },
      {
        path: "topics/:topicID",
        loader: async () => {
          return fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}topics-tree.json`
          );
        },
        element: <TopicsLeafPage />,
      },
      {
        path: "topic/:topicID",
        loader: async ({ params }) => {
          const res = await fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}topic/${params.topicID}.json`
          );
          if (res.status === 403) {
            throw new Response("Topic not found", {
              statusText: "Topic Not Found",
              status: "403",
            });
          }
          return res.json();
        },
        element: <TopicListPage />,
      },
      {
        path: "new",
        loader: async () => {
          return fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}latest.json`
          );
        },
        element: <LatestPage />,
      },
      {
        path: "new.html",
        element: <Navigate to="../new" />,
      },
      {
        path: "search",
        loader: async ({ request }) => {
          const url = new URL(request.url);
          const searchTerm = url.searchParams.get("query");
          return fetch(
            `${process.env.REACT_APP_SEARCH_API}?ResourceTypes=Demonstration&Format=json&WebFiltering=True&Count=1000&Query=${searchTerm}`
          );
        },
        element: <SearchPage />,
      },
      {
        path: "search.html",
        element: <Navigate to="../" />,
      },
      {
        path: "authors/:authorID",
        loader: async ({ params }) => {
          const res = await fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}authors/${params.authorID}.json`
          );
          if (res.status === 404) {
            throw new Response("Author not found", {
              statusText: "Author Not Found",
              status: "404",
            });
          }
          return res.json();
        },
        element: <AuthorListPage />,
      },
      {
        path: "author.html",
        element: <Navigate to="../" />,
      },
      {
        path: "upload.html",
        element: (
          <UploadPage
            createNotebookDownload={
              process.env.REACT_APP_CREATE_NOTEBOOK_DOWNLOAD
            }
          />
        ),
      },
      {
        path: "guidelines",
        element: (
          <GuidelinesPage
            guidelinesNotebook={
              process.env.REACT_APP_GUIDELINES_NOTEBOOK
            }
          />
        ),
      },
      {
        path: "guidelines.php",
        element: <Navigate to="../guidelines" />,
      },
      {
        path: ":pageID",
        loader: async ({ params }) => {
          const res = await fetch(
            `${process.env.REACT_APP_DEMONSTRATION_JSON_DIR}demonstrations/${params.pageID}/shingle.json`
          );
          if (res.status === 404) {
            throw new Response("Page not found", {
              statusText: "Page Not Found",
              status: "404",
            });
          }
          return res.json();
        },
        element: <DemonstrationPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<LoadingPage />} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useId, useState } from "react";
import { Link } from "react-router-dom";
import "./topic-tree.css";

function TopicSubCategory(props) {
  const treeID = useId();
  const [isShown, setIsShown] = useState(false);
  function showSection() {
    setIsShown((shown) => !shown);
  }
  const children = props.children.map((child) => (
    <TopicChild
      key={child.Title}
      name={child.Title}
      id={child.ID}
      children={child.Children}
    />
  ));
  return (
    <li>
      <button
        onClick={showSection}
        aria-expanded={isShown}
        aria-controls={treeID}
      >
        {props.name}
      </button>

      <ul id={treeID} hidden={!isShown}>
        {children}
      </ul>
    </li>
  );
}

function TopicChild(props) {
  if (props.children) {
    return <TopicSubCategory name={props.name} children={props.children} />;
  }
  return (
    <li>
      <Link to={"../topic/" + props.id}>{props.name}</Link>
    </li>
  );
}

function TopicRootCategory(props) {
  const treeID = useId();
  const [isShown, setIsShown] = useState(props.startOpen);
  function showSection() {
    setIsShown((shown) => !shown || props.startOpen);
  }
  function handleOnKeyDown(e) {
    console.log(e); 
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      showSection();
    }
  }

  const children = props.children.map((child) => (
    <TopicChild
      key={child.Title}
      name={child.Title}
      id={child.ID}
      children={child.Children}
    />
  ));

  return (
    <section className="main-topic">
      <div
        className="section-header"
        role="button"
        tabIndex="0"
        onClick={showSection}
        onKeyDown={handleOnKeyDown}
        aria-expanded={isShown}
        aria-controls={treeID}
        style={{ backgroundImage: `url(${props.icon})` }}
      >
        <h2>{props.name}</h2>
        <p>{props.caption}</p>
      </div>
      <ul id={treeID} hidden={!isShown}>
        {children}
      </ul>
    </section>
  );
}

export default function TopicTree(props) {
  const dropdowns = props.data.map((section) => (
    <TopicRootCategory
      key={section.Title}
      name={section.Title}
      icon={section.Icon}
      caption={section.Caption}
      children={section.Children}
      startOpen={props.startOpen || false}
    />
  ));
  return (
    <main className="main topic-tree">
      <section className="section">
        <div className="inner">
          <h1>{props.title}</h1>
          {dropdowns}
        </div>
      </section>
    </main>
  );
}
